import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _101941cf = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _61f9bdd8 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _5ecbcdbe = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _3681fc76 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _d72771e8 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _79b7f880 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _025d91ac = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _4b2d44a6 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _0fc60e41 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _7b7e7a6d = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _28851ffb = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _05c4c3a9 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _13ab995d = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _3788e872 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _c3044e1a = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _3df0a650 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _0d073eae = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _101941cf,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _61f9bdd8,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _5ecbcdbe,
    name: "callback"
  }, {
    path: "/downloads",
    component: _3681fc76,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _d72771e8,
    name: "guests-details"
  }, {
    path: "/login",
    component: _79b7f880,
    name: "login"
  }, {
    path: "/maintenance",
    component: _025d91ac,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _4b2d44a6,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _0fc60e41,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _7b7e7a6d,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _28851ffb,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _05c4c3a9,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _13ab995d,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _3788e872,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _c3044e1a,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _3df0a650,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _0d073eae,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
